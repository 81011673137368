import React from 'react';
import styles from './landing_layout.module.scss';
import { Container } from '@/components/layout/container';
import { LandingHeader } from '@/components/landing/header/landing_header';
import { LandingFooter } from '@/components/landing/footer/landing_footer';

export const LandingLayout: React.FC = props => {
  return (
    <Container>
      <LandingHeader />
      <section className={styles.content}>{props.children}</section>
      <LandingFooter />
    </Container>
  );
};
