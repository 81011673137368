import React from 'react';
import { Curriculum } from '../../repositories/course';
import { CurriculumContent } from './curriculum_content';
import { useTranslation } from 'react-i18next';

interface Props {
  curriculum: Curriculum;
  courseNumber?: number;
  limited?: boolean;
}
export const CurriculumDetail: React.FC<Props> = props => {
  const { t } = useTranslation();
  const month = new Date(props.curriculum.startDate).getMonth() + 1;
  const year = new Date(props.curriculum.startDate).getFullYear();
  let term = '';
  if (4 <= month && month <= 6) {
    term = t('courses.curriculum.spring_course_set', { year: year });
  } else if (7 <= month && month <= 9) {
    term = t('courses.curriculum.summer_course_set', { year: year });
    term = `${year}年夏期のコースセット`;
  } else if (10 <= month && month <= 12) {
    term = t('courses.curriculum.autumn_course_set', { year: year });
  } else if (1 <= month && month <= 3) {
    term = t('courses.curriculum.winter_course_set', { year: year });
  }

  return (
    <CurriculumContent
      title={`${term}「${props.curriculum.title}」`}
      courseNumber={props.courseNumber}
      desc={props.curriculum.desc}
      limited={props.limited}
    />
  );
};
