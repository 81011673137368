import React from 'react';
import styles from './top_flow.module.scss';
import { Card } from '../../../components/card/card';
export const TopFlow: React.FC = () => {
  const steps: { title: string; description: string }[] = [
    {
      title: 'ユーザー登録',
      description:
        '簡単なユーザー情報を入力すれば、体験となり、最新のコースを受講できます。',
    },
    {
      title: 'コースを選択',
      description:
        '気になるコースを選択します。入学プランに変更すれば、過去のコースも全て受講可能になります。',
    },
    {
      title: '講義を受講',
      description:
        '自分のペースで講義を受講し、終了時にテストを受ければ受講済みになります。',
    },
    {
      title: 'フォーラムで質問',
      description: '講義中などに出た疑問点は、フォーラムで質疑応答が可能です。',
    },
  ];
  const flow: JSX.Element[] = steps.map((step, index) => {
    return (
      <Card key={index} className={styles['step']} elevated={true}>
        <div className={styles['step-title']}>{step.title}</div>
        <p>{step.description}</p>
      </Card>
    );
  });
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>学習の流れ</div>
      <div className={styles.flow}>{flow}</div>
    </div>
  );
};
