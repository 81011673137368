import React from 'react';
import { APIErrorCode } from '@notionhq/client';
import { NextPageWithLayout } from '@/types/page';
import SEO from '@/components/head/seo';
import { LandingLayout } from '@/components/layout/landing_layout';
import { LandingTop } from '@/containers/landing/landing_top';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import {
  getReleaseNoteDatabase,
  getReleaseNotePosts,
} from '../utils/release_note';
import { getNewsDatabase, getNewsPosts } from '../utils/news';

export const Page: NextPageWithLayout = ({
  releaseNotePosts,
  newsPosts,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const title = 'AMDhaus | 国内唯一の建築専門オンライン学校';
  const description =
    'AMDhausは、オンラインの建築学校です。意匠、構造、環境設備はもちろんのこと、建築を切り口とした情報技術、経営、映像やプロダクト等、様々な分野の第一線で活躍されている方の講義を受けることができます。';

  return (
    <>
      <SEO title={title} description={description} />
      <LandingTop releaseNotePosts={releaseNotePosts} newsPosts={newsPosts} />
    </>
  );
};

Page.getLayout = page => {
  return <LandingLayout>{page}</LandingLayout>;
};
export const getStaticProps: GetStaticProps = async () => {
  let retryCount = 0;
  let releaseNoteData;
  let releaseNotePosts;
  let newsData;
  let newsPosts;
  while (retryCount < 3) {
    retryCount++;
    try {
      if (releaseNoteData === undefined) {
        releaseNoteData = await getReleaseNoteDatabase();
      }
      if (releaseNotePosts === undefined) {
        releaseNotePosts = await getReleaseNotePosts(releaseNoteData);
      }
      if (newsData === undefined) {
        newsData = await getNewsDatabase();
      }
      if (newsPosts === undefined) {
        newsPosts = await getNewsPosts(newsData);
      }
      break;
    } catch (error: any) {
      // クライアント起因のエラーはリトライを諦める
      const serverErrorCodes = [
        APIErrorCode.ConflictError,
        APIErrorCode.ServiceUnavailable,
        APIErrorCode.InternalServerError,
      ];
      if (!serverErrorCodes.includes(error?.code)) {
        break;
      }
    }
  }

  return {
    props: {
      releaseNotePosts: releaseNotePosts?.splice(0, 3) || [],
      newsPosts: newsPosts?.splice(0, 5) || [],
    },
    revalidate: 30,
  };
};

export default Page;
