import React, { useState } from 'react';
import { Label } from '../labels/label';
import styles from './curriculum_content.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  desc?: string;
  courseNumber?: number;
  forceExpand?: boolean;
  limited?: boolean;
}
export const CurriculumContent: React.FC<Props> = props => {
  const { t } = useTranslation();
  const forceExpand = props.forceExpand ?? false;
  const [showDetail, updateShowDetail] = useState(forceExpand);
  const renderDesc = () => {
    if (!props.desc) {
      return <></>;
    }
    return (
      <div
        className={`${styles['curriculum-description']} ${
          showDetail ? styles['is-shown'] : ''
        }`}
      >
        <div className={styles['desc-body']}>{props.desc}</div>
        {!forceExpand && (
          <div
            className={styles['text-link']}
            onClick={() => updateShowDetail(!showDetail)}
          >
            {showDetail ? '閉じる' : '全文表示'}
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <div className={styles['curriculum-title']}>
        {props.limited && (
          <Label color="blue" inverse className={styles['label']}>
            {t('courses.limited')}
          </Label>
        )}
        <span className={styles['title-name']}>{props.title}</span>
        <span className={styles['number']}>
          {props.courseNumber !== undefined &&
            `(${t('courses.course_number', { number: props.courseNumber })})`}
        </span>
      </div>
      {renderDesc()}
    </>
  );
};
