import React from 'react';
import styles from './landing_top.module.scss';

import { TopMain } from '../../components/landing/top/top_main';
import { TopFeature } from '../../components/landing/top/top_feature';
import { TopCurriculum } from '../../components/landing/top/top_curriculum';
import { TopFlow } from '../../components/landing/top/top_flow';
import { TopPrice } from '../../components/landing/top/top_price';
import { TopReleaseNote } from '../../components/landing/top/top_release_note';
import { TopNews } from '../../components/landing/top/top_news';
import { ReleaseNotePost } from '../../utils/release_note';
import { NewsPost } from '../../utils/news';
interface Props {
  releaseNotePosts: ReleaseNotePost[];
  newsPosts: NewsPost[];
}
export const LandingTop: React.FC<Props> = props => {
  return (
    <div className={styles['top']}>
      <TopMain />
      <TopFeature />
      <TopCurriculum />
      <TopFlow />
      <TopPrice />
      <TopReleaseNote releaseNotePosts={props.releaseNotePosts} />
      <TopNews newsPosts={props.newsPosts} />
    </div>
  );
};
