import React from 'react';
import styles from './top_release_note.module.scss';
import { Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReleaseNotePost } from '../../../utils/release_note';
import Image from 'next/image';
interface Props {
  releaseNotePosts: ReleaseNotePost[];
}
export const TopReleaseNote: React.FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>{t('landing.release_note.title')}</div>

      <ul className={styles['release-note']}>
        {props.releaseNotePosts.map((post, i) => (
          <li className={styles['release-note-item']} key={i}>
            <a href={post.url} target="_blank" rel="noreferrer">
              <div className={styles['img-wrap']}>
                <Image
                  src={post.thumbnailUrl}
                  alt={post.title}
                  height="140"
                  width="248"
                  objectFit="contain"
                />
              </div>
              <p>{post.title}</p>
            </a>
          </li>
        ))}
      </ul>
      <div className={styles['page-links']}>
        <a
          className={styles['link']}
          href="https://releasenote.amdhaus.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon>chevron_right</Icon>
          {t('landing.release_note.read_more')}
        </a>
      </div>
    </div>
  );
};
