import React from 'react';
import styles from './header.module.scss';

interface Props {
  color?: 'black' | 'white';
  className?: string;
}

export const Header: React.FC<Props> = props => {
  const color = props.color ?? 'black';
  const styleColor = `header_${color}`;
  return (
    <header
      className={`${styles.header}  ${styles[styleColor]} ${props.className}`}
    >
      {props.children}
    </header>
  );
};
