import React from 'react';
import styles from './top_feature.module.scss';
import Image from 'next/image';
export const TopFeature: React.FC = () => {
  const features: {
    title: string;
    imgUrl: string;
    width: string;
    height: string;
  }[] = [
    {
      title: `常に変わり続ける
      多様な講師陣`,
      imgUrl: '/next-assets/images/landing/feature_01.svg',
      width: '201',
      height: '186',
    },
    {
      title: `すきま時間で
      最新の知識や技術を学べる`,
      imgUrl: '/next-assets/images/landing/feature_02.svg',
      width: '196',
      height: '196',
    },
    {
      title: `フォーラムなどで
      学習をフォローアップ`,
      imgUrl: '/next-assets/images/landing/feature_03.svg',
      width: '205',
      height: '194',
    },
  ];
  const featureList: JSX.Element[] = features.map((feature, index) => {
    return (
      <dl key={index} className={styles['feature']}>
        <dt>{feature.title}</dt>
        <dd>
          <Image
            width={feature.width}
            height={feature.height}
            objectFit="contain"
            src={feature.imgUrl}
            alt={feature.title}
          />
        </dd>
      </dl>
    );
  });
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>特徴</div>
      <div className={styles['feature-list']}>{featureList}</div>
      <p className={styles['feature-description']}>
        AMDhausは、1年を4つの学期に分けたクォーター制となっています。
        <br />
        各学期ごとに最新のコースセットを設け、学期が終了すると、全て過去のコースにアーカイブされます。
        <br />
        最新のコースと過去のコースから学びたいコースを自ら選び受講することができます。
      </p>
      <dl className={styles['schedule']}>
        <dt>年間スケジュール</dt>
        <dd>
          <Image
            src="/next-assets/images/landing/schedule.svg"
            alt="年間スケジュール"
            width="868"
            height="96"
            objectFit="contain"
          />
        </dd>
      </dl>
    </div>
  );
};
