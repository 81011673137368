import React from 'react';
import styles from './label.module.scss';
interface Props {
  color?: 'white' | 'gray' | 'blue' | 'green' | 'yellow';
  size?: 'large' | 'medium' | 'small';
  className?: string;
  inverse?: boolean;
}

export const Label: React.FC<Props> = props => {
  const color = props.color ?? 'white';
  return (
    <div
      className={`${styles['label']} ${
        color ? styles[color] : styles['white']
      } ${props.size ? styles[props.size] : styles['medium']} ${
        props.className ? props.className : ''
      } ${props.inverse ? styles['inverse'] : ''}`}
    >
      {props.children}
    </div>
  );
};
