import React from 'react';
import styles from './top_price.module.scss';
import { Button } from '../../../components/buttons/button';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
export const TopPrice: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>授業料</div>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>体験</th>
              <th>入学</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>定額料金</th>
              <td>無料</td>
              <td className={styles['campaign']}>
                無料
                <br />
                （キャンペーン中）
              </td>
            </tr>
            <tr>
              <th>当期のコース</th>
              <td colSpan={2}>
                <Image
                  width="24"
                  height="24"
                  objectFit="contain"
                  src="/next-assets/images/landing/circle.svg"
                  alt="○"
                />
                受講し放題
              </td>
            </tr>
            <tr>
              <th>過去のコース</th>
              <td>
                <Image
                  width="20"
                  height="20"
                  objectFit="contain"
                  src="/next-assets/images/landing/ng.svg"
                  alt="×"
                />
                受講不可
              </td>
              <td>
                <Image
                  width="24"
                  height="24"
                  objectFit="contain"
                  src="/next-assets/images/landing/circle.svg"
                  alt="○"
                />
                受講し放題
              </td>
            </tr>
            <tr>
              <th>コースへの質問</th>
              <td>
                <Image
                  width="26"
                  height="23"
                  objectFit="contain"
                  src="/next-assets/images/landing/triangle.svg"
                  alt="△"
                />
                閲覧のみ・書き込み不可
              </td>
              <td>
                <Image
                  width="24"
                  height="24"
                  objectFit="contain"
                  src="/next-assets/images/landing/circle.svg"
                  alt="○"
                />
                閲覧・書き込み可
              </td>
            </tr>
            <tr>
              <th>フォーラムの使用</th>
              <td>
                <Image
                  width="26"
                  height="23"
                  objectFit="contain"
                  src="/next-assets/images/landing/triangle.svg"
                  alt="△"
                />
                閲覧のみ・書き込み不可
              </td>
              <td>
                <Image
                  width="24"
                  height="24"
                  objectFit="contain"
                  src="/next-assets/images/landing/circle.svg"
                  alt="○"
                />
                閲覧・書き込み可
              </td>
            </tr>
          </tbody>
        </table>
        <Button
          pattern="primary"
          size="large"
          onClick={() => router.push('/signup')}
          label={t('landing.signup')}
        />
      </div>
    </div>
  );
};
