import React, { RefObject } from 'react';
import { Button as BaseButton } from '@material-ui/core';
import styles from './button.module.scss';
import { Icon } from '@material-ui/core';
interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  bold?: boolean;
  type?: 'button' | 'submit' | 'reset';
  size?: 'large' | 'medium' | 'small';
  fullWidth?: boolean;
  pattern?: 'primary' | 'normal' | 'ghost' | 'danger';
  label?: string;
  inverse?: boolean;
  ref?: RefObject<HTMLButtonElement> | null;
  className?: string;
}
export const Button: React.FC<Props> = props => {
  const type = props.type ?? 'button';
  const pattern = props.pattern ?? 'normal';
  const isIconButton = props.icon && !props.label;
  const sizeStyle = props.size ? `size_${props.size}` : 'size_medium';
  return (
    <BaseButton
      id={props.id}
      classes={{
        root: styles.btn,
      }}
      className={`${styles[pattern]} ${isIconButton && styles.icon} ${
        styles[sizeStyle]
      } ${props.inverse && styles['is-inverse']} ${
        props.className && props.className
      }`}
      type={type}
      ref={props.ref}
      onClick={props.onClick}
      disabled={props.disabled}
      variant="contained"
      fullWidth={props.fullWidth}
    >
      {props.icon && <Icon className={styles.btn_icon}>{props.icon}</Icon>}
      {props.label && props.label}
      {props.children}
    </BaseButton>
  );
};
