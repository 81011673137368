import React, { RefObject, useRef } from 'react';
import styles from './card.module.scss';
interface scrollOutput {
  height: number;
  scrollTop: number;
  fullHeight: number;
}
export interface Props {
  className?: string;
  onClick?: () => void;
  onScroll?: (input: scrollOutput) => void;
  elevated?: boolean;
}
export const Card: React.FC<Props> = props => {
  const ref = useRef(null) as RefObject<HTMLElement> | undefined;
  return (
    <div
      className={`${styles['card']} ${props.className} ${
        props.onClick || props.elevated ? styles['is-elevated'] : ''
      }`}
      onClick={props.onClick}
      onScroll={() => {
        if (props.onScroll) {
          props.onScroll({
            scrollTop: ref?.current?.scrollTop ?? 0,
            height: ref?.current?.clientHeight ?? 0,
            fullHeight: ref?.current?.scrollHeight ?? 0,
          });
        }
      }}
    >
      {props.children}
    </div>
  );
};
