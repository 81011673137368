import { createSelector } from '@reduxjs/toolkit';
import { Course, COURSE_TYPE } from '../repositories/course';
import { CourseStatus } from '../repositories/student';
import { ApplicationState } from '../store';
import { dayjs } from '@/utils/time';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);
const selectSelf = (state: ApplicationState) => state;

const now = dayjs();
export const courseSelector = createSelector(selectSelf, state => {
  const pastCurriculums = state.courses.curriculums.filter(
    c => !dayjs().isBetween(dayjs(c.startDate), dayjs(c.endDate), null, '[)'),
  );
  const pastCourseIDs = pastCurriculums.flatMap(c => [...c.courseIDs]);
  const pastCourses = state.courses.courses.filter(c => {
    return pastCourseIDs.includes(c.id);
  });
  return {
    courseCategories: state.courses.courseCategories,
    currentCourses: state.courses.courses.filter(
      c => c.type === COURSE_TYPE.NORMAL && c.inCurrentQuarter,
    ),
    pastCourses,
    liveCourses: state.courses.courses.filter(
      c =>
        c.type === COURSE_TYPE.LIVE &&
        now.isBefore(dayjs(c.releasedAt).endOf('day')),
    ),
    permanentCourses: state.courses.courses.filter(
      c => c.type === COURSE_TYPE.PERMANENT,
    ),
    searchedCourses: state.courses.searchedCourses,
    searchedCurrentCourses: state.courses.searchedCourses.filter(c => {
      if (
        (c.type === COURSE_TYPE.NORMAL && c.inCurrentQuarter) ||
        c.type === COURSE_TYPE.PERMANENT ||
        (c.type === COURSE_TYPE.LIVE &&
          now.isBefore(dayjs(c.releasedAt).endOf('day')))
      ) {
        return c;
      }
    }),
    searchedPastCourses: state.courses.searchedCourses.filter(
      c => !c.inCurrentQuarter,
    ),
    courseSort: state.courses.courseSort,
    currentCurriculum: state.courses.curriculums.filter(c => {
      if (dayjs().isBetween(dayjs(c.startDate), dayjs(c.endDate), null, '[)')) {
        return c;
      } else {
        return null;
      }
    })[0],
    pastCurriculums,
  };
});

export interface CourseStatusWithDetail {
  status: CourseStatus;
  detail: Course;
  progress: number;
}

// export const myCourseSelector = createSelector(selectSelf, state => {
//   return state.students.courseStatus.reduce(
//     (prev, status) => {
//       const courseID = status.courseID;
//       const course = state.courses.courses.find(c => c.id === courseID);
//       if (!course) {
//         return prev;
//       }
//       const quiz = state.students.courseQuizzesStatus.find(
//         cqs => cqs.courseID === courseID,
//       );
//       // TODO: Fix to add lecture length in consideration
//       const progress =
//         (quiz?.pass ? 1 : 0 + status.lectureProgresses.length) / 10;
//       const result = {
//         detail: course,
//         status: status,
//         progress,
//       };
//       if (progress >= 0.9) {
//         prev.completed.push(result);
//       } else {
//         prev.inProgress.push(result);
//       }
//       return prev;
//     },
//     {
//       inProgress: [] as CourseStatusWithDetail[],
//       completed: [] as CourseStatusWithDetail[],
//     },
//   );
// });
