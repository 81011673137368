import React from 'react';
import { Icon } from '@material-ui/core';
import { Label } from '../labels/label';
import { Card } from '../card/card';
import styles from './course_list.module.scss';
import { Course, COURSE_TYPE } from '../../repositories/course';
import { NameAvator } from '../avator/name_avator';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import useMedia from 'use-media';
import { formatDate } from '../../utils/time';
import Image from 'next/image';
interface Props {
  course: Course;
  onClick?: () => void;
  className?: string;
  progressRate?: number;
  isLoading?: boolean;
  wide?: boolean;
}

export const CourseList: React.FC<Props> = props => {
  const { t } = useTranslation();
  const isWindowLg = useMedia({ maxWidth: '999px' });
  const renderTag = props.course.tags.map((tag, index) => (
    <span className={styles.tag} key={index}>
      {tag}
    </span>
  ));
  const renderIcon =
    props.course.inCurrentQuarter === true &&
    props.course.type === COURSE_TYPE.NORMAL ? (
      <div className={styles['is-current']}>New</div>
    ) : (
      ''
    );
  const router = useRouter();
  const renderStatus =
    router.pathname === '/dashboard/courses' &&
    props.course.isEnrolled === true ? (
      <div className={styles['is-enrolled']}>
        <Icon>check</Icon>
        {t('courses.enrolled')}
      </div>
    ) : (
      ''
    );
  const style = {
    width: `${props.progressRate}%`,
  };
  const renderProgressRate = () => {
    if (props.progressRate === undefined) {
      return '';
    }
    const rate = Math.round(props.progressRate);
    return (
      <div className={styles['progress-rate']}>
        <div className={styles['progress-bar']}>
          <span style={style}></span>
        </div>
        {rate}%
      </div>
    );
  };
  const renderCourseDetail = () => {
    if (props.isLoading) {
      const loadingImg = isWindowLg ? (
        <Image
          width="269"
          height="77"
          objectFit="contain"
          src="/next-assets/images/course_skeleton_sp.svg"
          alt=""
        />
      ) : (
        <Image
          width="269"
          height="225"
          objectFit="contain"
          src="/next-assets/images/course_skeleton.svg"
          alt=""
        />
      );
      return loadingImg;
    } else {
      return (
        <>
          {renderIcon}
          {renderStatus}
          <Label color="gray">{props.course.category}</Label>
          <div className={styles['course-info']}>
            <NameAvator
              className={styles['avator']}
              avatorURL={props.course.teacher.avatorURL}
              size="l"
            />
            <div>
              <div className={styles['course-title']}>{props.course.title}</div>
              <div className={styles['course-detail-info']}>
                <span>
                  公開日：{formatDate(props.course.releasedAt as string)}
                </span>
                <span>ID：{props.course.id}</span>
              </div>
              <div className={styles['course-subscription']}>
                {props.course.enrolledStudentsCount}
                {t('courses.people_have_enrolled')}
              </div>
              <div className={styles['course-teacher']}>
                {t('teachers')}：
                {`${props.course.teacher.lastName} ${props.course.teacher.firstName}`}
              </div>
            </div>
          </div>
          <div className={styles['course-description']}>
            {props.course.desc}
          </div>
          <div className={styles['course-tags']}>{renderTag}</div>
          <div className={styles['course-teacher']}>
            {t('teachers')}：
            {`${props.course.teacher.lastName} ${props.course.teacher.firstName}`}
          </div>
          {renderProgressRate()}
        </>
      );
    }
  };
  return (
    <Card
      className={`${styles['course-item']} ${
        props.className ? props.className : ''
      } ${!props.isLoading ? styles['is-shown'] : ''}
       ${props.wide ? styles['wide'] : ''}`}
      onClick={props.onClick}
    >
      {renderCourseDetail()}
    </Card>
  );
};
