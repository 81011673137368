import React from 'react';
import styles from './name_avator.module.scss';
import { CircleImage } from '../images/circle_image';
interface Props {
  lastName?: string;
  firstName?: string;
  avatorURL?: string;
  size?: 's' | 'm' | 'l' | 'xl';
  inverse?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const NameAvator: React.FC<Props> = props => {
  const sizeStyle = props.size ? `size_${props.size}` : 'size_s';
  const showName = props.lastName && props.firstName;
  const fullName = `${props.lastName} ${props.firstName}`;
  return (
    <div
      className={`${styles.avator} ${props.className} ${styles[sizeStyle]} ${
        props.inverse && styles.inverse
      } ${props.onClick ? styles['is-clickable'] : ''}`}
      onClick={props.onClick}
    >
      <CircleImage
        src={props.avatorURL}
        alt={fullName ?? 'user image'}
        size={props.size}
      />
      <div>
        {props.children}
        {showName && <span>{fullName}</span>}
      </div>
    </div>
  );
};
