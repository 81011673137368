import React, { useEffect, useState } from 'react';
import styles from './landing_header.module.scss';
import { Button } from '../../../components/buttons/button';
import { Header } from '../../../components/header/header';
import { NLink } from '@/components/link/n_link';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon } from '@material-ui/core';
import { ApplicationState } from '../../../store';
import { getSelf } from '../../../store/users/actions';
import { useRouter } from 'next/router';
import Image from 'next/image';
export const LandingHeader: React.FC<unknown> = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation();
  const [showMenu, updateShowMenu] = useState(false);
  useEffect(() => {
    dispatch(getSelf());
  }, [dispatch]);
  const self = useSelector((state: ApplicationState) => {
    return state.users.self;
  });

  const menus: { label: string; labelEn: string; route: string }[] = [
    {
      label: t('landing.navigation.top'),
      labelEn: 'TOP',
      route: '/',
    },
    {
      label: t('landing.navigation.course'),
      labelEn: 'COURSE',
      route: '/courses',
    },
    {
      label: t('landing.navigation.teachers'),
      labelEn: 'TEACHER',
      route: '/teachers',
    },
  ];
  const switchPage = (route: string) => {
    updateShowMenu(false);
    router.push(route);
  };
  const menuList: JSX.Element[] = menus.map((menu, index) => {
    return (
      <NLink
        onClick={() => {
          updateShowMenu(false);
        }}
        className={styles['menu']}
        key={index}
        href={menu.route}
      >
        <div className={styles['menu-label']}>{menu.label}</div>
        <div className={styles['menu-label-en']}>{menu.labelEn}</div>
      </NLink>
    );
  });

  const toggleNews = () => {
    updateShowMenu(!showMenu);
  };
  const renderHeaderButtons = () => {
    if (self.idToken && self.lastName !== '' && self.firstName !== '') {
      return (
        <>
          <Button
            pattern="primary"
            onClick={() => router.push('/dashboard/mypage')}
            label={t('mypage')}
          />
        </>
      );
    } else {
      return (
        <>
          <Button onClick={() => router.push('/signin')} label={t('signin')} />
          <Button
            pattern="primary"
            onClick={() => router.push('/signup')}
            label={t('signup')}
          />
        </>
      );
    }
  };
  return (
    <Header color="white" className={styles['landing-header']}>
      <div className={styles['left-area']}>
        <div className={styles['logo']} onClick={() => switchPage('/')}>
          <Image
            src="/next-assets/images/logo_beta.svg"
            alt="logo"
            height="22"
            width="212"
            objectFit="contain"
          />
        </div>
        <div
          className={`${styles['menu-list']} ${showMenu ? styles['show'] : ''}`}
        >
          {menuList}
          <Button icon="close" inverse={true} onClick={toggleNews} />
        </div>
      </div>
      <div className={styles['right-area']}>
        {renderHeaderButtons()}
        <Icon className={styles['menu-btn']} onClick={toggleNews}>
          menu
        </Icon>
      </div>
    </Header>
  );
};
