import React from 'react';
import styles from './top_news.module.scss';
import { Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../components/card/card';
import { NewsPost } from '../../../utils/news';
import { formatDate } from '@/utils/time';
interface Props {
  newsPosts: NewsPost[];
}
export const TopNews: React.FC<Props> = props => {
  const { t } = useTranslation();
  return (
    <div className={styles['container']}>
      <div className={styles['title']}>{t('landing.news.title')}</div>
      <Card elevated={true} className={styles['news']}>
        {props.newsPosts.map((post, i) => (
          <dl className={styles['news-item']} key={i}>
            <dt>{formatDate(post.date)}</dt>
            <dd>
              <a href={post.url} target="_blank" rel="noreferrer">
                {post.title}
              </a>
            </dd>
          </dl>
        ))}
      </Card>
      <div className={styles['page-links']}>
        <a
          className={styles['link']}
          href="https://news.amdhaus.com/"
          target="_blank"
          rel="noreferrer"
        >
          <Icon>chevron_right</Icon>
          {t('landing.news.read_more')}
        </a>
      </div>
    </div>
  );
};
