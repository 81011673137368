import React, { useEffect } from 'react';
import styles from './top_curriculum.module.scss';
import { Icon } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  quarterCoursesGet,
  currentCurriculumGet,
} from '../../../store/courses/action';
import { CourseList } from '../../../components/courses/course_list';
import { CurriculumDetail } from '../../../components/curriculums/curriculum_detail';
import { Course } from '../../../repositories/course';
import { ApplicationState } from '../../../store';
import { courseSelector } from '../../../selectors/course';

export const TopCurriculum: React.FC = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(quarterCoursesGet());
    dispatch(currentCurriculumGet());
  }, [dispatch]);
  const { currentCurriculum } = useSelector((state: ApplicationState) => {
    return {
      currentCurriculum: state.courses.curriculums[0],
    };
  });
  const { currentCourses } = courseSelector(
    useSelector((state: ApplicationState) => state),
  );

  const displayCourse = (data: Course[]) =>
    data.map((d, index) => (
      <CourseList
        className={styles['course-list']}
        course={d}
        key={index}
        onClick={() => router.push(`/courses/${d.id}`)}
      />
    ));
  return (
    <div className={styles['container-wrapper']}>
      <div className={styles['container']}>
        <div className={styles['title']}>当期のコースセット</div>
        {currentCurriculum && (
          <CurriculumDetail curriculum={currentCurriculum} />
        )}
        <div className={styles['course-list-wrap']}>
          {displayCourse(currentCourses)}
        </div>
        <div className={styles['page-links']}>
          <div
            className={styles['link']}
            onClick={() => router.push('/courses')}
          >
            <Icon>chevron_right</Icon>その他のコースをみる
          </div>
          <div
            className={styles['link']}
            onClick={() => router.push('/teachers')}
          >
            <Icon>chevron_right</Icon>講師一覧をみる
          </div>
        </div>
      </div>
    </div>
  );
};
