import React from 'react';
import styles from './top_main.module.scss';
import { Button } from '../../../components/buttons/button';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
export const TopMain: React.FC = () => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <>
      <main className={styles['main-img']}>
        <Image
          src="/next-assets/images/landing/main.png"
          alt="a school of architecture"
          objectFit="contain"
          width="1280"
          height="752"
        />
      </main>
      <div className={styles['container']}>
        <div className={styles['title']}>
          AMDhausは、
          <br className={styles['pc-none']} />
          オンラインの建築学校です。
        </div>
        <p>
          意匠、構造、環境設備はもちろんのこと
          <br />
          建築を切り口とした情報技術、経営、映像やプロダクト等
          <br />
          様々な分野の第一線で活躍されている方の講義を受けることができます。
        </p>
        <Button
          pattern="primary"
          size="large"
          onClick={() => router.push('/signup')}
          label={t('landing.signup')}
        />
      </div>
    </>
  );
};
