import Link from 'next/link';
import React from 'react';
import styles from './n_link.module.scss';

export interface Props {
  href: string;
  className?: string;
  target?: '_self' | '_blank';
  rel?: string;
  onClick?: () => void;
}

export const NLink: React.FC<Props> = props => {
  const className = props.className || 'n-link';
  const target = props.target || '_self';
  const rel = props.rel || '';

  return (
    <Link href={props.href}>
      <a
        className={`${styles['anchor']} ${className}`}
        target={target}
        rel={rel}
        onClick={props.onClick}
      >
        {props.children}
      </a>
    </Link>
  );
};
