import React from 'react';
import styles from './landing_footer.module.scss';
import { Icon } from '@material-ui/core';
import { NLink } from '@/components/link/n_link';
import Image from 'next/image';
export const LandingFooter: React.FC = () => {
  return (
    <footer className={styles['landing-footer']}>
      <div className={styles['sns-links']}>
        <NLink
          href="https://twitter.com/AmDhaus"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            width="32"
            height="32"
            objectFit="contain"
            src="/next-assets/images/snsLogos/twitter.svg"
          />
        </NLink>
      </div>
      <div className={styles['page-links']}>
        <NLink href="/privacypolicy">プライバシーポリシー</NLink>
        <NLink href="/termsofservice">利用規約</NLink>
        <NLink href="/actonspecifiedcommercialtransactions">
          特定商取引法に基づく表示
        </NLink>
        <NLink
          href="https://amd-lab.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon>open_in_new</Icon>運営会社
        </NLink>
        <NLink
          href="https://share.hsforms.com/1m9Y5OOG7TBSbQAllPWW2-A8ncxe"
          target="_blank"
          rel="noopener noreferrer"
        >
          お問い合わせ
        </NLink>
      </div>
    </footer>
  );
};
