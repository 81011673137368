import React from 'react';
import { getAvatorImage } from '../../utils/avator';
import styles from './circle_image.module.scss';
import Image from 'next/image';

type Size = 's' | 'm' | 'l' | 'xl';

interface Props {
  size?: Size;
  src?: string;
  alt: string;
}

const sizeToPx = (size: Size): number => {
  switch (size) {
    case 's':
      return 24;
    case 'm':
      return 40;
    case 'l':
      return 56;
    case 'xl':
      return 90;
    default:
      return 90;
  }
};

export const CircleImage: React.FC<Props> = props => {
  const size = props.size ?? 'm';

  return (
    <div className={styles[`circle-image-${size}`]}>
      <Image
        className={styles['circle-image']}
        src={getAvatorImage(props.src)}
        alt={props.alt}
        quality={95}
        priority={true}
        height={sizeToPx(size)}
        width={sizeToPx(size)}
        objectFit="cover"
      />
    </div>
  );
};
