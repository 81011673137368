import React from 'react';
import Head from 'next/head';

type Props = {
  title: string;
  description?: string;
  image?: string;
};

const SEO: React.FC<Props> = ({ title, description, image }) => {
  const ogImage = image ?? '/next-assets/main.png';

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta
        name="description"
        property="og:description"
        content={description || ''}
      />
      <meta name="og:image" property="og:image" content={ogImage} />
      <meta name="og:site_name" property="og:site_name" content="AMDhaus" />
      <meta name="og:type" property="og:type" content="website" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description || ''} />
      <meta name="twitter:test" content={description || ''} />
      <meta name="twitter:image" content={ogImage} />
    </Head>
  );
};

export default SEO;
